
          @import "src/styles/_variables.scss";
          // Adicione outros arquivos globais aqui se necessário
        
.card-review{
    padding: 24px;
    border-radius: 16px;
    background: $color-secondary;

    h3,p{
        margin: 0;
        color: $color-white;
    }

    .box-text {

        p {
            font-size: 1rem;
            line-height: 1.5em;
        }
    }

    .author-info {
        margin-top: 16px;

        h3 {
            display: inline-flex;
            align-items: center;
            gap: 4px;
            font-size: 2rem;
            line-height: 1.25em;
    
            small {
                font-size: .5em;
            }
        }
    }

}