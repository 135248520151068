
          @import "src/styles/_variables.scss";
          // Adicione outros arquivos globais aqui se necessário
        
.layout-header {
    padding: 24px 0;
    
    .section-content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    
    .layout-logo,
    .social-media {
        
        @media #{$screen-lg}{
            width: 25%;

        }
    }
    
    .layout-nav{
        display: none;
        
        @media #{$screen-lg}{
            display: flex;
            justify-content: center;
            width: 50%;
        }
    }
    
    .social-media {
        display: none;

        @media #{$screen-lg}{
            display: flex;
            justify-content: flex-end;
        }
    }
}
