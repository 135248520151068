
          @import "src/styles/_variables.scss";
          // Adicione outros arquivos globais aqui se necessário
        
.profile {
    
    .container {
        display: flex;        
        flex-direction: column;
        gap: 24px;
        
        @media #{$screen-sm}{
            align-items: center;
            flex-direction: row;
        }

        .left-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            
            h1 {
                margin: 0 0 16px;
            }
            
            [data-custom-resumn] {
                
                @media #{$screen-md}{
                    max-width: 85%;
                }
            }

            .button {
                margin-top: 42px;
            }
        }
        
        .right-content {
            flex-shrink: 0;
            order: -1;
            
            @media #{$screen-sm}{                
                width: 49%;
                order: unset;
            }

            img {
                aspect-ratio: 1/1;
                border-radius: 100%;
            }
        }
    }
}