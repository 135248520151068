
          @import "src/styles/_variables.scss";
          // Adicione outros arquivos globais aqui se necessário
        
.skills {
       
    .section-content{
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        
        @media #{$screen-lg}{            
            gap: 16px;
        }
    }
}