
          @import "src/styles/_variables.scss";
          // Adicione outros arquivos globais aqui se necessário
        
.social-media {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 8px;
}