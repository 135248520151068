
          @import "src/styles/_variables.scss";
          // Adicione outros arquivos globais aqui se necessário
        
.layout-logo {
    max-width: 220px;

    @media #{$screen-xs}{
        max-width: unset;
    }
}