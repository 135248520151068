$font-primary: 'Inter',sans-serif;

$color-primary: #01161E;
$color-secondary: #124559;
$color-tertiary: #67b7a0;
$color-white: #fefefe;
$color-black: #2b2b2b;

$screen-xl: 'only screen and (min-width:1600px)';
$screen-lg: 'only screen and (min-width:1200px)';
$screen-md: 'only screen and (min-width:992px)';
$screen-sm: 'only screen and (min-width:768px)';
$screen-xs: 'only screen and (min-width:480px)';