
          @import "src/styles/_variables.scss";
          // Adicione outros arquivos globais aqui se necessário
        
.layout-footer {
    margin-top: 40px;
    padding: 24px 0;
    background: $color-primary;
    
    @media #{$screen-sm}{
        margin-top: 80px;
    }
    
    .section-content {
        display: flex;
        justify-content: space-between;

        .layout-logo {
            filter: brightness(0) invert(1);
        }
    }
}