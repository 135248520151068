
          @import "src/styles/_variables.scss";
          // Adicione outros arquivos globais aqui se necessário
        
.list-portfolio{
    margin-top: 48px;

    .section-header{
        margin-bottom: 48px;

        h2 {
            margin: 0 0 24px;
            font-size: 2.5rem;
            line-height: 1.1em;
            color: $color-primary;
        }
    }

    .section-content{
        display: flex;
        flex-wrap: wrap;
        gap: 48px 24px;

        .card-portfolio{
            flex-shrink: 0;
            width: 100%;
            
            @media #{$screen-sm}{
                width: calc(65% - 14px);
            }

            &.portrait{
                
                @media #{$screen-sm}{
                    width: calc(35% - 10px);
                }
            }
        }
    }
}

.list-buttons {
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    gap: 8px;
    
    @media #{$screen-sm}{
        flex-wrap: wrap;
    }
    
    @media #{$screen-lg}{
        gap: 16px;
    }

    .button {
        scroll-snap-align: start;
    }
}