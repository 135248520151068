
          @import "src/styles/_variables.scss";
          // Adicione outros arquivos globais aqui se necessário
        
.text-image {

    &.alternate {

        .container {

            @media #{$screen-sm}{
    
                .right-content {
                    order: -1;
                }
            }
        }
    }

    .container{
        display: grid;
        grid-template-columns: repeat(1,1fr);
        gap: 24px;
        
        @media #{$screen-sm}{
            gap: 48px;
            grid-template-columns: repeat(2,1fr);
        }

        .left-content {

            p {
                margin: 16px 0 0;
                font-weight: 600;
                font-size: 1rem;
                line-height: 1.5em;
                color: $color-black;
                
                @media #{$screen-sm}{
                    margin: 32px 0 0;
                    font-size: 1.15rem;
                }
                
                @media #{$screen-xl}{
                    font-size: 1.3rem;
                }

                a {
                    display: inline;
                    color: $color-tertiary;
        
                    &[href]{
                        transition: color 300ms ease-in-out;
        
                        &:hover {
                            color: $color-secondary;
                        }
                    }
                }
            }
        }

        .right-content {
            order: -1;

            @media #{$screen-sm}{
                order: unset;
            }

            img{
                border-radius: 16px;
            }
        }
    }
}