
          @import "src/styles/_variables.scss";
          // Adicione outros arquivos globais aqui se necessário
        
.button{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background: $color-secondary;
    padding: 4px;
    border-radius: 45px;
    min-height: 48px;
    transition: background 300ms ease-in-out, transform 300ms ease-in-out;
    
    @media #{$screen-lg}{
        padding: 8px;
    }

    &[href],
    &[aria-label]{
        position: relative;        

        &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            border-radius: 45px;
            background: rgba(0,0,0,.5);
            mix-blend-mode: overlay;
            opacity: 0;
            transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
        }

        &:hover{
            
            &:before{
                opacity: 1;
            }
        }
    }

    &.active{
        pointer-events: none;
        transform: scale(.9);

        &:before{
            opacity: 1;
        }
    }

    &.only-icon {
        border-radius: 100%;

        &[href],
        &[aria-label]{
    
            &:hover{
                transform: scale(.9);
            }
        }
        
        .icon {
            padding: 4px;
            background: transparent;
        }
    }

    &.invert-position {

        .icon + span {        
            order: -1;
            padding: 0 0 0 8px;
        }
    }

    &.sm-border{
        padding: 4px;        
    }
    
    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 2;
        border-radius: 100%;
        padding: 8px;
        min-height: 40px;
        min-width: 40px;
        background: $color-white;

        +span {
            padding: 0 8px 0 0;
        }
    }    

    span {
        font-weight: 700;
        font-size: 1rem;
        color: $color-white;  
        
        @media #{$screen-lg}{
            font-size: 1.125rem;
        }
    }
}