
          @import "src/styles/_variables.scss";
          // Adicione outros arquivos globais aqui se necessário
        
.section-home {
    margin-top: 40px;
    
    @media #{$screen-lg}{
        margin-top: 80px;
    }

    .container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        
        @media #{$screen-lg}{
            gap: 32px;
        }
    }

    &.portfolio {

        .section-header{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            gap: 16px;
            
            @media #{$screen-sm}{
                align-items: center;
                flex-direction: row;
                gap: 32px;                
            }
        }
    }
}