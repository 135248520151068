
          @import "src/styles/_variables.scss";
          // Adicione outros arquivos globais aqui se necessário
        
.reviews {
    display: flex;
    flex-direction: column;
    gap: 32px;
    
    .section-content{

        .swiper {
            padding: 0 15px;
            
            @media #{$screen-sm}{
                padding: 0 24px;
            }
        }

        .swiper-pagination{

            .swiper-pagination-bullet {
                width: 15px;
                height: 15px;
                background: $color-secondary;
            }
        }
    }
}