
          @import "src/styles/_variables.scss";
          // Adicione outros arquivos globais aqui se necessário
        
.card-portfolio{
    cursor: pointer;
    position: relative;

    &.portrait{

        .box-img > img {
            aspect-ratio: 9/9.65;
        }
    }

    &:hover{

        .box-img{

            &:before{
                opacity: 1;
            }

            .button{
                transform: translate(-50%,-50%) scaleX(1);
                transition-delay: 150ms;
            }
        }
    }
    
    .categories {
        display: flex;
        gap: 8px;
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 2;
    }

    .box-img {
        position: relative;
        border-radius: 16px;
        overflow: hidden;

        &:before{
            content: '';
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            background: rgba(0,0,0,.5);
            opacity: 0;
            transition: opacity 300ms ease-in-out;
        }

        > img{
            border-radius: 16px;
            aspect-ratio: 10/5.75;
        }

        .button{
            position: absolute;
            z-index: 2;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%) scaleX(0);
            transition: transform 200ms ease-in-out;
            pointer-events: none;
        }            
    }

    .box-text {
        margin-top: 16px;

        h3,p {
            margin: 0;
            color: $color-primary;
        }

        h3 {
            font-size: 1.5rem;
            line-height: 1.25em;
            
            @media #{$screen-xs}{
                font-size: 2rem;
            }
        }

        p {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
            overflow: hidden;
            margin-top: 0;
            font-size: .875rem;
            line-height: 1.5em;
            
            @media #{$screen-xs}{
                font-size: 1rem;
            }

            @media #{$screen-sm}{                
                margin-top: 16px;
            }
        }
    }
}