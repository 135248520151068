
          @import "src/styles/_variables.scss";
          // Adicione outros arquivos globais aqui se necessário
        
.layout-nav {
    display: none;

    @media #{$screen-lg}{
        display: block;
    }

    ul{
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        gap: 24px;

        li {

            a {
                position: relative;
                font-weight: 700;
                font-size: 1.25rem;
                line-height: 1em;
                // color: $color-primary;

                &:hover,
                &.active{

                    &:before{
                        transform: scaleX(1);
                    }
                }

                &.active {
                    pointer-events: none;
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    height: 4px;
                    border-radius: 4px;
                    background: currentColor;
                    transform: scaleX(0);
                    transition: transform 300ms ease-in-out;
                    transform-origin: center left;
                }
            }
        }
    }
}

.toggle-menu-mobile {
    
    @media #{$screen-lg}{
        display: none;
    }
}

.menu-mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-color: rgba(0, 0, 0, 0.9);
    z-index: 10;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // opacity: 0;
    // transition: opacity 300ms ease-in-out;
    pointer-events: none;
    
    &.open {
        pointer-events: auto;

        .menu-mobile-content {            
            transform: none;
        }
    }

    .menu-mobile-content {
        display: flex;
        flex-direction: column;
        padding: 50px 25px;
        margin: 0;
        transform: translateX(100%);
        height: 100vh;
        background: $color-tertiary;
        transition: transform 300ms ease-in-out;

        .toggle-menu-mobile {
            margin-left: auto;
        }

        .social-media{
            margin-top: auto;
            display: flex;
            justify-content: center;
        }
    }

    ul {
        position: relative;
        z-index: 2;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            color: $color-white;
            font-weight: 700;
            font-size: 2rem;
            text-align: center;
        }
    }
}
  