
          @import "src/styles/_variables.scss";
          // Adicione outros arquivos globais aqui se necessário
        
* {
    &,
    &:before,
    &:after {
        box-sizing: border-box;
    }        
} 

html {
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    background: #f5f5f5;
    font: 16px/1.5 $font-primary;
    -webkit-tap-highlight-color: $color-white;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    // scroll-behavior: smooth;
    // scroll-padding-top: 83px;
}

body {
    margin: 0;    
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.layout-main {
    margin-bottom: auto;
}

img,iframe,svg {
    display: block;
}

svg {
    fill: currentColor;
}

a {
    display: block;
    text-decoration: none;
    color: inherit;
}

p {
    margin-top: 0;
}

figure {
    margin: 0;
}

input {
    width: 100%;
    background: transparent;
    border: none;
    font-family: inherit;
    color: inherit;
    outline: none;
}

input:required {
    box-shadow: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
    appearance: none;
}

button {
    border: none;
    background: transparent;
    font: inherit;
    appearance: none;
    cursor: pointer;
}

textarea{
    font-family: 'Roboto';
    resize: none;
    outline: none;
    border: none;
}

:focus {
    outline: none;
}

:active {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

::placeholder {
  color: inherit;
  opacity: 1;
}

.img-responsive {
    max-width: 100%;
    display: block;
    height: auto;
}
.img-cover,
.img-contain {
    display: block;
    width: 100%;
    height: 100%;
}
.img-cover {
    object-fit: cover;
}
.img-contain {
    object-fit: contain;
}

.container {
    max-width: 1470px;
    padding: 0 15px;
    width: 100%;
    margin: 0 auto;

    @media #{$screen-sm}{
        max-width: 750px;
    }

    @media #{$screen-md}{
        max-width: 970px;
    }

    @media #{$screen-lg}{
        max-width: 1170px;
    }
    
    @media #{$screen-xl}{    
        max-width: 1470px;
    }
}

[data-custom-title]{
    margin: 0;
    color: $color-primary;
}

[data-custom-title~="banner"]{
    font-size: 2rem;
    line-height: 1.1em;
    
    @media #{$screen-xs}{
        font-size: 2.8rem;
    }

    @media #{$screen-sm}{
        font-size: 2rem;
    }
    
    @media #{$screen-lg}{
        font-size: 3.5rem;
    }
    
    @media #{$screen-xl}{
        font-size: 4rem;
    }
}

[data-custom-title~="section"]{    
    font-size: 1.7rem;
    line-height: 1.3em;

    @media #{$screen-sm}{
        font-size: 1.8rem;
    }
    
    @media #{$screen-lg}{
        font-size: 2.5rem;    
    }
    
    @media #{$screen-xl}{
        font-size: 3rem;
    }
}

[data-custom-resumn]{

    p {
        
        &:last-child {
            margin: 0;
        }
    }    
}

[data-custom-resumn~="banner"]{

    p {
        font-size: 1rem;
        line-height: 1.5em;
        color: $color-primary;
        
        @media #{$screen-md}{
            font-size: 1.15rem;
        }
        
        @media #{$screen-lg}{
            font-size: 1.25rem;
        }
        
        @media #{$screen-xl}{
            font-size: 1.5rem;
        }
    }
}